import { useEffect, useState } from 'react'

type AlertState = { [key: string]: string }

const useDismissAlert = (initialState: AlertState, delay: number) => {
  const [state, setState] = useState<AlertState>(initialState)

  useEffect(() => {
    const shouldDismiss = Object.values(state).some((msg) => msg)

    if (shouldDismiss) {
      const timer = setTimeout(() => {
        setState(
          Object.keys(state).reduce((acc, key) => ({ ...acc, [key]: '' }), {})
        )
      }, delay)

      return () => clearTimeout(timer)
    }
  }, [state, delay])

  return [state, setState] as const
}

export default useDismissAlert
