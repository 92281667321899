import React from 'react'
import Image from 'next/image'
import { ChevronLeft } from 'react-feather'

interface FormBackgroundLayoutProps {
  title?: string
  subtitle?: React.ReactNode
  children?: React.ReactNode
  showBackButton?: boolean
  onBackButtonClick?: () => void
}

const FormBackgroundLayout: React.FC<FormBackgroundLayoutProps> = ({
  title,
  subtitle,
  children,
  showBackButton = false,
  onBackButtonClick
}) => {
  return (
    <section className="d-flex align-items-center justify-content-center min-vh-100 custom-background">
      <div className="container py-3 px-0">
        <div className="row justify-content-center">
          <div className="col-12 col-md-8 col-lg-6 col-xl-5">
            <div
              className="card shadow-2-strong"
              style={{
                borderRadius: '1rem',
                backgroundColor: 'rgba(0, 0, 0, 0.8)'
              }}
            >
              <div className="card-body p-5 text-center">
                <div className="mb-4">
                  <Image
                    src="/kegow-business.svg"
                    alt="Kegow Business Logo"
                    className="mt-1 mb-5 pb-1 img-fluid"
                    width={440}
                    height={32}
                    priority
                  />
                </div>
                {showBackButton && onBackButtonClick && (
                  <div className="d-flex align-items-center mb-4">
                    <ChevronLeft
                      size={24}
                      className="text-white me-3"
                      style={{ cursor: 'pointer' }}
                      onClick={onBackButtonClick}
                    />
                    <h3 className="mb-3 text-white">{title}</h3>
                  </div>
                )}
                {!showBackButton && title && (
                  <h3 className="mb-3 text-white">{title}</h3>
                )}
                <p className="text-white-50 mb-4">{subtitle}</p>
                {children}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default FormBackgroundLayout
