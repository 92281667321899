import { FirebaseApp, initializeApp } from 'firebase/app'
import { getStorage, FirebaseStorage } from 'firebase/storage'
import {
  ReCaptchaEnterpriseProvider,
  initializeAppCheck,
  AppCheck
} from '@firebase/app-check'

let firebase: FirebaseApp | null = null
let appCheck: AppCheck | null = null
let storage: FirebaseStorage | null = null

if (typeof window !== 'undefined') {
  const firebaseConfig = {
    apiKey: process.env.NEXT_PUBLIC_FIREBASE_API_KEY,
    authDomain: process.env.NEXT_PUBLIC_FIREBASE_AUTH_DOMAIN,
    databaseURL: process.env.NEXT_PUBLIC_FIREBASE_DATABASE_URL,
    projectId: process.env.NEXT_PUBLIC_FIREBASE_PROJECT_ID,
    storageBucket: process.env.NEXT_PUBLIC_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.NEXT_PUBLIC_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.NEXT_PUBLIC_FIREBASE_APP_ID,
    measurementId: process.env.NEXT_PUBLIC_FIREBASE_MEASUREMENT_ID
  }

  firebase = initializeApp(firebaseConfig)
  storage = getStorage(firebase)

  // // Debug token for development purposes
  if (process.env.NEXT_PUBLIC_FIREBASE_APPCHECK_DEBUG_TOKEN) {
    (window as any).FIREBASE_APPCHECK_DEBUG_TOKEN =
      process.env.NEXT_PUBLIC_FIREBASE_APPCHECK_DEBUG_TOKEN;
  }

  appCheck = initializeAppCheck(firebase, {
    provider: new ReCaptchaEnterpriseProvider(
      process.env.NEXT_PUBLIC_RECAPTCHA_ENTERPRISE_SITE_KEY as string
    ),
    isTokenAutoRefreshEnabled: true // Set to true to allow auto-refresh.
  })
}

export { firebase, appCheck, storage }
