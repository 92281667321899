import {
  Transaction,
  TransactionResult,
  TransactionStatement,
  TransactionType,
  TransferWalletToBankDTO
} from '../interfaces/appInterface'
import CryptoJS from 'crypto-js'

export const formatCurrency = (amount: number): string => {
  return new Intl.NumberFormat('en-NG', {
    style: 'currency',
    currency: 'NGN'
  }).format(amount ?? 0)
}

/**
 * Maps status strings to corresponding CSS classes for badges.
 * @param status - The status string to map to a CSS class.
 * @returns The corresponding CSS class for the given status string.
 */
export const badge: Record<string, string> = {
  APPROVED: 'badge text-bg-success',
  COMPLETED: 'badge text-bg-success',
  SUCCESS: 'badge text-bg-success',
  SUCCESSFUL: 'badge text-bg-success',
  FAILED: 'badge text-bg-danger',
  DECLINED: 'badge text-bg-danger',
  REJECTED: 'badge text-bg-danger',
  PENDING: 'badge text-bg-warning'
}

/**
 * Converts an object to snake case.
 * @param obj - The object to be converted.
 * @returns An object with snake case keys and the same values.
 */
export const convertObjToSnakeCase = (
  obj: Record<string, unknown>
): Record<string, unknown> => {
  const result: Record<string, unknown> = {}
  for (const [key, value] of Object.entries(obj)) {
    const snakeCaseKey: string = key
      .replace(/([A-Z])/g, '_$1')
      .toLowerCase()
      .replace(/^_/, '')
    result[snakeCaseKey] = value
  }
  return result
}

export const capitalizeFirstLetter = (string: string) => {
  if (!string) return ''
  return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase()
}

export function switchTransType(
  transactionType: string,
  beneficiary: string,
  accountNumber: string,
  bulkTransferId?: string,
  payrollId?: string
): TransactionResult {
  const type: TransactionType =
    accountNumber === beneficiary ? 'credit' : 'debit'

  // Determine the name of the transaction based on type and whether it's intra or inter
  let name: string
  if (
    payrollId &&
    (transactionType.toLowerCase() === 'intra-outward' ||
      transactionType.toLowerCase() === 'inter-outward')
  ) {
    name = 'payroll'
  } else if (
    bulkTransferId &&
    (transactionType.toLowerCase() === 'intra-outward' ||
      transactionType.toLowerCase() === 'inter-outward')
  ) {
    name = 'bulk transfer'
  } else if (
    transactionType.toLowerCase() === 'intra-inward' ||
    transactionType.toLowerCase() === 'intra-outward'
  ) {
    name = `Kegow Transfer (${type})`
  } else if (
    transactionType.toLowerCase() === 'inter-inward' ||
    transactionType.toLowerCase() === 'inter-outward'
  ) {
    name = `Bank Transfer (${type})`
  } else {
    name = transactionType
  }
  return {
    type,
    name
  }
}

export const getAccountName = (
  transaction: Transaction,
  businessAccountNumber: string
) => {
  const beneficiaryAccountNumber = transaction.beneficiary_account_number
  const originatorAccountName = transaction.originator_account_name
  const beneficiaryAccountName = transaction.beneficiary_account_name
  const { type } = getTransactionType(transaction, businessAccountNumber)
  return type === 'credit' ? originatorAccountName : beneficiaryAccountName
}
export const getTransactionType = (
  transaction: Transaction | TransactionStatement,
  businessAccountNumber: string
) => {
  const beneficiaryAccountNumber = transaction.beneficiary_account_number
  // const accountNumber = businessData?.accountDetails[0].accountNumber ?? ''
  const transactionType = transaction.transaction_type
  const { type, name } = switchTransType(
    transactionType,
    beneficiaryAccountNumber,
    businessAccountNumber,
    transaction.bulk_transfer_id as string,
    transaction.payroll_id as string
  )
  return { type, name }
}

export const getInitials = (firstname: string, lastname: string) => {
  const initials =
    (lastname
      ? `${firstname?.charAt(0)}${lastname?.charAt(0)}`
      : firstname?.charAt(0)) || ''
  return initials.toUpperCase()
}

export const today = new Date().toISOString().split('T')[0]

export const encryptData = (data: any) => {
  const key = CryptoJS.enc.Utf8.parse(
    process.env.NEXT_PUBLIC_KEGOW_ENCRYPTION_KEY ?? ''
  )
  const iv = CryptoJS.enc.Utf8.parse(
    process.env.NEXT_PUBLIC_KEGOW_ENCRYPTION_KEY ?? ''
  )
  const ciphertext = CryptoJS.AES.encrypt(JSON.stringify(data), key, {
    iv,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7
  }).toString()
  return ciphertext
}

export const decryptData = (ciphertext: string) => {
  const key = CryptoJS.enc.Utf8.parse(
    process.env.NEXT_PUBLIC_KEGOW_ENCRYPTION_KEY ?? ''
  )
  const iv = CryptoJS.enc.Utf8.parse(
    process.env.NEXT_PUBLIC_KEGOW_ENCRYPTION_KEY ?? ''
  )

  const bytes = CryptoJS.AES.decrypt(ciphertext, key, {
    iv,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7
  })

  const decryptedData = bytes.toString(CryptoJS.enc.Utf8)
  return JSON.parse(decryptedData)
}

export const isValidPhoneNumber = (phoneNumber: string): boolean => {
  const phoneNumberPattern =
    /^(234(80|81|70|71|90|91)[0-9]{8}|(080|081|070|071|090|091)[0-9]{8})$/
  return phoneNumberPattern.test(phoneNumber)
}
