'use client'
import React, { ChangeEvent, useState, useEffect } from 'react'
import { useRouter } from 'next/navigation'
import Link from 'next/link'
import { Eye, EyeOff } from 'react-feather'
import axiosReq from '../interceptor'
import { encryptData } from '../utils/helpers'
import FormBackgroundLayout from '../components/form-background-layout'
import '../onboarding/onboarding.css'
import useDismissAlert from '../Hooks/useDismissAlert'
import { useDispatch } from 'react-redux'
import { resetState } from '../app-store/businessDataSlice'

const Login: React.FC = () => {
  const dispatch = useDispatch()
  const [isLoading, setIsLoading] = useState(false)
  const [areFieldsSet, setAreFieldsSet] = useState(false)
  const [showPassword, setShowPassword] = useState(false)
  const [formData, setFormData] = useState({ email: '', password: '' })
  const [error, setError] = useDismissAlert({ email: '', password: '' }, 5000)
  const [loginError, setLoginError] = useDismissAlert({ message: '' }, 5000)
  const router = useRouter()

  // Update `areFieldsSet` to handle both user input and browser autofill.
  useEffect(() => {
    setAreFieldsSet(formData.email !== '' && formData.password !== '')
  }, [formData.email, formData.password])

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword)
  }

  const validateEmail = (email: string): boolean => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/
    const startsWithNumber = /^\d/.test(email)
    return emailRegex.test(email) && !startsWithNumber
  }

  const validateForm = () => {
    let valid = true
    const error: { email: string; password: string } = {
      email: '',
      password: ''
    }

    if (!validateEmail(formData.email)) {
      error.email = 'Please enter a valid email.'
      valid = false
    }

    if (formData.password === '') {
      error.password = 'Password is required.'
      valid = false
    }

    setError(error)
    return valid
  }

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target
    setFormData({ ...formData, [name]: value })
    setLoginError({ message: '' })
  }

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault()
    localStorage.clear()
    sessionStorage.clear()
    dispatch(resetState())

    if (!validateForm()) return

    setIsLoading(true)

    const payload = {
      ...formData,
      email: formData.email.toLowerCase()
    }

    try {
      const { status, data } = await axiosReq.post(
        '/app/v2/auth/sign-in',
        payload
      )
      if (status === 200) {
        const { token, user, business } = data
        localStorage.setItem('token', token)
        localStorage.setItem('uo', encryptData(user)) //  uo as user object.
        if (business.kyc_status === 'approved') {
          return router.push('/dashboard/overview')
        }
        // router.push(`/login/otp?email=${formData.email}`)
        router.push('/dashboard/kyc')
      }
    } catch (error: any) {
      setIsLoading(false)

      if (error.response) {
        const { status, data } = error.response

        if (status === 400) {
          if (data && typeof data.message === 'string') {
            setError({ email: '', password: data.message })
          } else if (data && data.errors) {
            setError({
              email: data.errors.email || '',
              password: data.errors.password || ''
            })
          }
        } else {
          setLoginError({
            message:
              'Login failed. Please check your email and password and try again.'
          })
        }
      } else {
        setLoginError({
          message:
            'Network error. Please check your internet connection and try again later.'
        })
      }
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <FormBackgroundLayout
      title="Welcome"
      subtitle={
        <>
          New here?{' '}
          <Link prefetch href="/onboarding" className="text-white">
            Create Account
          </Link>
        </>
      }
    >
      <form onSubmit={handleSubmit}>
        <div className="form-outline mb-4">
          <label className="form-label text-white" htmlFor="email">
            Email
          </label>
          <input
            type="email"
            id="email"
            name="email"
            className="form-control mb-3"
            placeholder="Enter your registered email"
            value={formData.email}
            onChange={handleChange}
            required
            disabled={isLoading}
          />
          {error.email && (
            <div className="alert alert-danger">
              <small>{error.email}</small>
            </div>
          )}
        </div>

        <div className="form-outline mb-4 position-relative">
          <label className="form-label text-white" htmlFor="password">
            Password
          </label>
          <div className="input-group mb-3">
            <input
              type={showPassword ? 'text' : 'password'}
              id="password"
              name="password"
              className="form-control"
              placeholder="Enter your password"
              required
              value={formData.password}
              onChange={handleChange}
              style={{ borderRadius: '0.375rem' }}
            />
            <div
              className="input-group-append"
              onClick={togglePasswordVisibility}
            >
              {showPassword ? <EyeOff size={14} /> : <Eye size={14} />}
            </div>
          </div>
          {error.password && (
            <div className="alert alert-danger">
              <small>{error.password}</small>
            </div>
          )}
        </div>

        <div className="text-end mb-4">
          <Link
            prefetch
            href="/forgot-password"
            className="text-white-50"
            style={{ fontSize: '14px' }}
          >
            Forgot Password?
          </Link>
        </div>

        {loginError.message && (
          <div className="alert alert-danger">
            <small>{loginError.message}</small>
          </div>
        )}

        <button
          className="btn btn-primary rounded-pill w-100 btn-block"
          type="submit"
          disabled={isLoading || !areFieldsSet}
        >
          {isLoading ? 'Logging you in...' : 'Login'}
        </button>
      </form>

      {isLoading && (
        <div className="mt-4">
          <div className="spinner-border text-light" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      )}
    </FormBackgroundLayout>
  )
}

export default Login
