'use client'
import axios from 'axios'
import { toast } from 'react-toastify'
import { appCheck } from './services/firebase'
import { getToken } from '@firebase/app-check'

const axiosReq = axios.create({
  baseURL: process.env.NEXT_PUBLIC_API_BASE_URL
})

// Dynamically update the token in the headers, whenever it changes when making requests.
axiosReq.interceptors.request.use(
  async (config) => {
    if (typeof window !== 'undefined') {
      const token = localStorage.getItem('token')
      if (token) {
        config.headers.Authorization = `Bearer ${token}`
      }

      // Add request interceptor to include App Check token.
      try {
        if (appCheck) {
          const appCheckToken = await getToken(appCheck)
          if (appCheckToken?.token) {
            config.headers['X-Firebase-AppCheck'] = appCheckToken.token
          }
        }
      } catch (error) {
        console.error('Error obtaining App Check token:', error)
      }
    }

    return config
  },
  function (error) {
    return Promise.reject(error)
  }
)

axiosReq.interceptors.response.use(
  function (response) {
    return response
  },
  function (error) {
    if ([401].includes(error.response?.status)) {
      if (error.response.data.redirect && error.response.data.redirect === false) {
        toast(error.response?.data.message ?? error.response?.data.msg ?? '', {
          type: 'error',
          toastId:
            error.response?.data.message ?? error.response?.data.msg ?? ''
        })
      } else {
        localStorage.clear()
        sessionStorage.clear()
        return window.location.replace('/login')
      }
    }
    if (error.response?.status === 403) {
      if (error.response.data.redirect) {
        localStorage.clear()
        sessionStorage.clear()
        return window.location.replace('/login')
      } else if (
        error.response.data.message ===
        'Business not verified, only verified users can access this resource'
      ) {
        toast(
          error.response.data.message ??
            'You do not have access to this resource',
          { type: 'warning', toastId: 'businessNotVerified' }
        )
      } else {
        toast(
          error.response.data.message ??
            'You do not have access to this resource',
          { type: 'warning' }
        )
      }
    } else {
      toast(error.response?.data.message ?? error.response?.data.msg ?? '', {
        type: 'error',
        toastId: error.response?.data.message ?? error.response?.data.msg ?? ''
      })
    }
    return Promise.reject(error) // Handle and display error messages to the user.
  }
)

export default axiosReq
